import { Box, styled } from '@mui/material';

const MainContentBlock = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  marginTop: '65px',
  height: '100%',
  margin: 0
}));

export default MainContentBlock;
