import { styled } from '@mui/material';

export const StayUpToDateContainer = styled('div')({
  textAlign: 'center'
});

export const StayUpToDateIconContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  margin: '50px 0 50px'
});

export const StayUpToDateIcon = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 98,
  height: 98,
  borderRadius: '50%',
  border: '2px solid #1976d2',
  color: '#1976d2',

  '& svg': {
    width: '60px',
    height: '60px'
  }
});

export const StayUpToDateHeading = styled('h2')({
  fontWeight: 500,
  fontSize: '40px',
  lineHeight: '52px',
  marginTop: '40px'
});

export const StayUpToDateDescription = styled('h4')({
  fontSize: '16px',
  lineHeight: '26px',
  fontWeight: '400',
  marginTop: '20px'
});

export const StayUpToDateSearchContainer = styled('div')({
  marginTop: '30px'
});

export const StayUpToDateSearch = styled('div')({});
