import { useState, useEffect } from 'react';
import { Poi } from './PoiMarkers';
import axiosInstance from '../../utils/axiosConfig';

export const useGoogleMap = () => {
    const center = {
        lat: 54.05,
        lng: -122.55
    }
    
    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await axiosInstance.get('/pins')
            setLocations(response.data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };
    const [locations, setLocations] = useState<Poi[]>([
        { key: '', location: { lat: center.lat, lng: center.lng } }
    ]);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    return {
        locations,
        center,
        isLoading
    }
}