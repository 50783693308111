import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography
} from '@mui/material';
import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CustomInput } from '../../../components';
import { AppDispatch } from '../../../redux';
import {
  deleteUserAction,
  editUserAction,
  getUserAction
} from '../../../redux/actions/authActions';
import { showSnackbar } from '../../../redux/slices/snackbarSlice';
import { UserRoles } from '../../../redux/types';
import { getUserIdFromToken } from '../../../utils';

interface UserData {
  name: string;
  surname: string;
  userName: string;
  email: string;
  phoneNumber: string;
  address: string;
  id: string;
  role: UserRoles;
}

const initialUserData = {
  name: '',
  surname: '',
  userName: '',
  email: '',
  phoneNumber: '',
  address: '',
  id: '',
  role: UserRoles.User
};

const AccountProfilePage: FC = () => {
  const [initialUser, setInitialUser] = useState<UserData | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState<UserData>({ ...initialUserData });
  const [formErrors, setFormErrors] = useState<{ [key: string]: string[] }>({});
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const userId = getUserIdFromToken();

  const loadUserData = useCallback(async () => {
    if (!userId) {
      dispatch(showSnackbar({ message: 'You are not authenticated', severity: 'error' }));
      navigate('/login');
      return;
    }

    if (initialUser) return;

    await dispatch(getUserAction(setInitialUser, setFormData));
  }, [userId, initialUser]);

  useEffect(() => {
    if (!initialUser) {
      loadUserData();
    }
  }, [initialUser, loadUserData]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleCancel = () => {
    setIsEditing(false);
    setFormData(initialUser || initialUserData);
    setFormErrors({});
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    dispatch(editUserAction(formData, setFormErrors, setInitialUser, setIsEditing));
  };

  const handleDelete = async () => {
    dispatch(deleteUserAction(navigate));
  };

  const handleRoleChange = (event: SelectChangeEvent<UserRoles>) => {
    setFormData({
      ...formData,
      role: event.target.value as UserRoles
    });
  };

  const renderInput = useCallback(
    (label: string, name: keyof UserData, value: string, disabled: boolean) => (
      <Box sx={{ marginTop: '15px' }}>
        <Typography>{label}</Typography>

        <CustomInput
          name={name}
          value={value || ''}
          variant="standard"
          onChange={handleInputChange}
          disabled={disabled}
          sx={{
            '& .Mui-disabled.MuiInputBase-root::before': {
              borderBottomStyle: disabled ? 'none' : 'none'
            }
          }}
        />

        {formErrors[name]?.map((error, index) => (
          <Typography key={index} color="error" variant="caption">
            {error}
          </Typography>
        ))}
      </Box>
    ),
    [formData, isEditing, formErrors]
  );

  return (
    <Box sx={{ maxWidth: 500, margin: 'auto', padding: '50px' }}>
      <Typography variant="h4" gutterBottom>
        User Profile
      </Typography>

      <Box sx={{ marginTop: '20px' }}>
        {formData && !isEditing ? (
          <>
            <Button variant="contained" color="primary" onClick={handleEdit}>
              Edit
            </Button>
          </>
        ) : (
          <>
            <div>
              <Button variant="contained" color="primary" onClick={handleSave}>
                Save
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancel}
                sx={{ ml: 2 }}
              >
                Cancel
              </Button>
            </div>
          </>
        )}
      </Box>

      {renderInput('Name', 'name', formData.name, !isEditing)}
      {renderInput('Surname', 'surname', formData.surname, !isEditing)}
      {renderInput('Address', 'address', formData.address, !isEditing)}
      {renderInput('Phone Number', 'phoneNumber', formData.phoneNumber, !isEditing)}
      {renderInput('User Name', 'userName', formData.userName, !isEditing)}
      {renderInput('Email', 'email', formData.email, !isEditing)}

      <Box sx={{ marginTop: '15px' }}>
        <FormControl fullWidth variant="standard" disabled={!isEditing}>
          <InputLabel id="role-label">Role</InputLabel>

          <Select labelId="role-label" value={formData.role} onChange={handleRoleChange}>
            <MenuItem value={UserRoles.User}>User</MenuItem>
            <MenuItem value={UserRoles.Developer}>Developer</MenuItem>
            <MenuItem value={UserRoles.Admin}>Admin</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          marginTop: '15px'
        }}
      >
        <Button variant="contained" color="secondary" onClick={handleDelete}>
          Delete current user
        </Button>
      </Box>
    </Box>
  );
};

export default AccountProfilePage;
