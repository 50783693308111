import { Box, Button, TextField, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { showSnackbar } from '../../../redux/slices/snackbarSlice';
import { AppDispatch } from '../../../redux/store';
import { AppRoutes } from '../../../routes';
import axiosInstance from '../../../utils/axiosConfig';

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const ForgoPasswordPage: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address');
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await axiosInstance.post('/password/reset/', { email });

      if (response.data.detail === 'The password sheet has been sent.') {
        dispatch(
          showSnackbar({
            message: 'Password reset instructions have been sent to your email.',
            severity: 'success'
          })
        );
      }

      setEmail('');
    } catch (err) {
      const typedError = err as AxiosError<{ email?: string[] }>;

      if (typedError.response?.data?.email) {
        const errorMessage = typedError.response.data.email.join(', ');

        dispatch(
          showSnackbar({
            message: errorMessage || 'Error occurred while processing your request.',
            severity: 'error'
          })
        );
      } else {
        dispatch(
          showSnackbar({
            message:
              'An error occurred while sending the request. Please try again later.',
            severity: 'error'
          })
        );
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          maxWidth: '500px',
          width: '100%',
          margin: '150px auto 30px',
          padding: '20px',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px'
        }}
      >
        <Typography variant="h4" gutterBottom textAlign={'center'}>
          Reset Password
        </Typography>
        <Typography marginTop={'30px'} variant="h6" gutterBottom textAlign={'center'}>
          Please enter the email address that you used to register, and we will send you a
          link to reset your password via Email.
        </Typography>

        <TextField
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={!!error}
          helperText={error}
          type="email"
          variant="outlined"
          fullWidth
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Sending...' : 'Reset Password'}
        </Button>

        <Box sx={{ textAlign: 'center', marginTop: '30px' }} color={'#1976d2'}>
          Return to
          <Button
            variant="text"
            component={Link}
            to={AppRoutes.REGISTER}
            sx={{
              textDecoration: 'underline',
              textTransform: 'none'
            }}
          >
            Sign In
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default ForgoPasswordPage;
