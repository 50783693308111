import { Button, ButtonProps, LinkProps, styled } from '@mui/material';
import React from 'react';

export const FooterSectionWrapper = styled('div')({
  backgroundColor: '#1F4B43'
});

export const FooterContainer = styled('div')({
  margin: '0 105px',
  padding: '20px 0'
});

export const FooterBlock = styled('div')({
  '&.footer-row-1': {
    display: 'flex',
    justifyContent: 'space-between'
  }
});

export const FooterSocialContainer = styled('div')({
  color: '#dfdfdf',
  display: 'flex',
  alignItems: 'center'
});

export const FooterSocialText = styled('p')({
  fontSize: '19px',
  paddingRight: '30px'
});

export const FooterSocialIcons = styled('div')({
  display: 'flex',
  gap: '30px'
});

export const FooterSocialLine = styled('div')({
  height: '1px',
  backgroundColor: '#dfdfdf',
  width: '100%',
  margin: '35px 0'
});

export const FooterLinksContainer = styled('div')({
  color: '#dfdfdf',
  width: '22.5%',
  textAlign: 'start'
});

export const FooterLinksHeading = styled('div')({
  fontSize: '19px',
  fontWeight: '600'
});

export const FooterLinksButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column'
});

export const FooterLinksList = styled('div')({
  display: 'flex',
  marginTop: '15px'
});

type FooterButtonProps = ButtonProps &
  LinkProps & {
    component?: React.ElementType;
    to?: string;
  };

export const FooterLinksButton = styled(Button)<FooterButtonProps>({
  justifyContent: 'start',
  color: '#dfdfdf',
  margin: 0,
  padding: 0,
  textTransform: 'initial',
  transition: 'all 3s ease-in-out',
  marginTop: '7px',

  '&:hover': {
    color: 'white',
    transform: 'scale(1.05)'
  }
});

export const FooterCopyrightText = styled('div')({
  textAlign: 'center',
  color: '#dfdfdf'
});
