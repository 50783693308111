import { Box } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';

const FullScreenLoader: React.FC<{ showLoading?: boolean }> = ({
  showLoading = false
}) => {
  const { loading } = useSelector((state: RootState) => state.auth);

  if (!loading || showLoading) return null;

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(81, 81, 81, 0.7)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999
      }}
    >
      <Box
        sx={{
          width: '100px',
          height: '100px',
          border: '15px solid #FFF',
          borderBottomColor: 'orange',
          borderRadius: '50%',
          display: 'inline-block',
          boxSizing: 'border-box',

          '@keyframes rotation': {
            '0%': { transform: 'rotate(0deg)' },
            '100%': { transform: 'rotate(360deg)' }
          },
          animation: 'rotation 1s linear infinite'
        }}
      />
    </Box>
  );
};

export default FullScreenLoader;
