import { Box } from '@mui/material';
import { FC } from 'react';
import {
  Achievements,
  FAQ,
  GoogleMap,
  ModernBuilding,
  Neighborhoods,
  SearchHome,
  StayUpToDate,
  WorkWithUs
} from '../../components';

const HomePage: FC = () => {
  return (
    <div>
      Home Page
      <Box>
        <SearchHome />

        <GoogleMap />

        <WorkWithUs />

        <Neighborhoods />

        <StayUpToDate />

        <ModernBuilding />

        <Achievements />

        <FAQ />
      </Box>
    </div>
  );
};

export default HomePage;
