import axios from 'axios';
import store from '../redux';
import { clearTokens, setTokens } from '../redux/slices';

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

const axiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json'
  }
});

// Interceptor to add token in Headers
axiosInstance.interceptors.request.use(
  async (config) => {
    const state = store.getState();
    const accessToken = state.auth.accessToken;

    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Interceptor to update token with error 401
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const refreshToken = store.getState().auth.refreshToken;

      try {
        if (refreshToken) {
          const response = await axios.post('/token/refresh/', {
            refresh: refreshToken
          });

          const { access, refresh } = response.data;

          store.dispatch(setTokens({ accessToken: access, refreshToken: refresh }));

          originalRequest.headers['Authorization'] = `Bearer ${access}`;

          return axiosInstance(originalRequest);
        }
      } catch (err) {
        store.dispatch(clearTokens());
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
