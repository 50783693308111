import { styled } from '@mui/material';

export const AchievementsSectionWrapper = styled('div')({});

export const AchievementsContainer = styled('div')({
  display: 'flex',
  width: '100%',
  margin: '50px 0'
});

export const AchievementsContainerBlock = styled('div')({
  width: '50%',

  '&.achievements-block-1': {
    padding: '40px 70px 40px 0'
  }
});

export const AchievementsHeading = styled('h2')({
  fontWeight: 500,
  fontSize: '40px',
  lineHeight: '52px'
});

export const AchievementsDescription = styled('p')({
  fontSize: '16px',
  lineHeight: '26px',
  fontWeight: '400',
  marginTop: '20px'
});

export const AchievementsDigitsItemWrapper = styled('div')({
  marginTop: '50px',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '20px 0'
});

export const AchievementsDigitsItem = styled('div')({
  width: '50%',
  flex: '1 1 calc(50% - 20px)'
});

export const AchievementsDigitsHeading = styled('p')({
  fontSize: '40px',
  lineHeight: '72px',
  fontWeight: '500'
});

export const AchievementsDigitsDescription = styled('p')({
  fontWeight: '500'
});

export const AchievementsButtonWrapper = styled('div')({
  marginTop: '50px'
});

export const AchievementPicture = styled('img')({
  width: '100%',
  height: '100%',
  maxHeight: '700px',
  objectFit: 'cover',
  borderRadius: '15px',
  boxShadow: '5px 5px 8px rgba(0, 0, 0, 0.11)'
});
