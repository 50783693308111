import { jwtDecode } from 'jwt-decode';

interface DecodedToken {
  user_id: string;
  jti: string;
  token_type: string;
  iat: number;
  exp: number;
}

export const getUserIdFromToken = (): string | null => {
  const accessToken = localStorage.getItem('accessToken');

  if (!accessToken) {
    return null;
  }

  try {
    const decodedToken = jwtDecode<DecodedToken>(accessToken);

    return decodedToken.user_id;
  } catch (error) {
    console.error('Error decoding token:', error);
    return null;
  }
};
