export const Routes = {
  HOME: '/',
  PROPERTIES: '/properties',
  FOR_INVESTORS: '/for-investors',
  FOR_DEVELOPERS: '/for-developers',
  HOW_IT_WORKS: '/how-it-works',
  ABOUT: '/about',
  CONTACT: '/contact',
  AUTH: '/login',
  REGISTER: '/register',
  FORGOT_PASSWORD: '/forgot-password',
  CONFIRM_PASSWORD: '/password/reset/confirm',

  ACCOUNT: '/account',
  ACCOUNT_DASHBOARD: '/account/dashboard',
  ACCOUNT_FAVORITES: '/account/favorites',
  ACCOUNT_INBOX: '/account/inbox',
  ACCOUNT_HISTORY: '/account/history',
  ACCOUNT_PROFILE: '/account/profile',
  ACCOUNT_PROPERTIES: '/account/properties',
  ACCOUNT_ADD_USER: '/account/add-user'
};

export const PageTitles = {
  HOME: 'Home',
  PROPERTIES: 'Properties',
  FOR_INVESTORS: 'For investors',
  FOR_DEVELOPERS: 'For developers',
  HOW_IT_WORKS: 'How It Works',
  ABOUT: 'About Us',
  CONTACT: 'Contact',
  AUTH: 'Authorization',
  REGISTER: 'Registration',

  ACCOUNT: 'Account',
  ACCOUNT_DASHBOARD: 'Dashboard',
  ACCOUNT_FAVORITES: 'Favorites',
  ACCOUNT_INBOX: 'Inbox',
  ACCOUNT_HISTORY: 'History',
  ACCOUNT_PROFILE: 'Profile',
  ACCOUNT_PROPERTIES: 'Properties',
  ACCOUNT_ADD_USER: 'Add User'
};
