import { FormControl, SelectChangeEvent, Toolbar } from '@mui/material';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LANGUAGES, LANGUAGE_COOKIE_KEY, Language } from '../../../constants';
import { StyledMenuItem, StyledSelect } from './LanguageSelector.styles';

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState<Language>(
    (Cookies.get(LANGUAGE_COOKIE_KEY) as Language) || LANGUAGES.ENGLISH
  );

  const handleChange = (event: SelectChangeEvent<unknown>): void => {
    const newLanguage = event.target.value as Language;

    setLanguage(newLanguage);
    Cookies.set(LANGUAGE_COOKIE_KEY, newLanguage);
    i18n.changeLanguage(newLanguage);
  };

  const languageOptions = [
    { value: LANGUAGES.ENGLISH, label: 'English' },
    { value: LANGUAGES.CHINESE, label: 'Chinese' },
    { value: LANGUAGES.FRENCH, label: 'French' }
  ];

  useEffect(() => {
    const savedLanguage = Cookies.get(LANGUAGE_COOKIE_KEY);

    if (savedLanguage && savedLanguage !== language) {
      setLanguage(savedLanguage as Language);
    }
  }, [language]);

  return (
    <Toolbar sx={{ justifyContent: 'flex-end' }}>
      <FormControl variant="outlined" sx={{ minWidth: 120 }}>
        <StyledSelect value={language} onChange={handleChange}>
          {languageOptions.map((option) => (
            <StyledMenuItem key={option.value} value={option.value}>
              {option.label}
            </StyledMenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    </Toolbar>
  );
};

export default LanguageSelector;
