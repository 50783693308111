import {
  Description as DescriptionIcon,
  Home as HomeIcon,
  VerifiedUser as VerifiedUserIcon
} from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { WorkWithUsCard } from './WorkWithUs.styles';

const services = [
  {
    title: 'Wide Range of Properties',
    description:
      'We offer expert legal help for all related property items in Vancouver.',
    icon: <DescriptionIcon fontSize="large" />
  },
  {
    title: 'Buy or Rent Homes',
    description:
      'We sell your property at the best market price and very quickly as well.',
    icon: <HomeIcon fontSize="large" />
  },
  {
    title: 'Trusted by Thousands',
    description: 'We offer you free consultancy to get a loan for your new home.',
    icon: <VerifiedUserIcon fontSize="large" />
  }
];

const WorkWithUs: FC = () => {
  return (
    <div className="sectionPadding">
      <Box
        className="containerDesktop"
        sx={{ padding: '40px 20px', textAlign: 'center' }}
      >
        <Typography variant="h2" sx={{ fontWeight: 500, marginBottom: '15px' }}>
          Why You Should Work With Us
        </Typography>

        <Typography variant="body1" color="text.secondary" fontStyle={'italic'} paragraph>
          Sell and buy property in Vancouver with ease — just a few clicks!
        </Typography>

        <Grid container spacing={4} justifyContent="center">
          {services.map((service, index) => (
            <Grid item xs={12} sm={4} key={index}>
              <WorkWithUsCard>
                {service.icon}
                <Typography variant="h6" gutterBottom>
                  {service.title}
                </Typography>
                <Typography variant="body2" sx={{ color: 'grey' }}>
                  {service.description}
                </Typography>
              </WorkWithUsCard>
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
};

export default WorkWithUs;
