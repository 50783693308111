import { createTheme } from '@mui/material/styles';

const primaryColor = '#1976d2';
const secondaryColor = '#dc004e';
const errorColor = '#f44336';
const warningColor = '#ff9800';
const infoColor = '#2196f3';
const successColor = '#4caf50';

const textPrimary = '#212121';
const textSecondary = '#757575';

const backgroundDefault = '#f5f5f5';
const backgroundPaper = '#ffffff';

// const shadows = [
//   'none',
//   '0px 1px 3px rgba(0, 0, 0, 0.2)',
//   '0px 1px 5px rgba(0, 0, 0, 0.2)',
//   '0px 1px 8px rgba(0, 0, 0, 0.2)'
// ];

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920
  }
};

const palette = {
  primary: {
    main: primaryColor
  },
  secondary: {
    main: secondaryColor
  },
  error: {
    main: errorColor
  },
  warning: {
    main: warningColor
  },
  info: {
    main: infoColor
  },
  success: {
    main: successColor
  },
  text: {
    primary: textPrimary,
    secondary: textSecondary
  },
  background: {
    default: backgroundDefault,
    paper: backgroundPaper
  }
};

const typography = {
  fontFamily: 'Fredoka, sans-serif',
  h1: {
    fontSize: '2.5rem', // 40px
    fontWeight: 700
  },
  h2: {
    fontSize: '2rem', // 32px
    fontWeight: 600
  },
  h3: {
    fontSize: '1.75rem', // 28px
    fontWeight: 500
  },
  h4: {
    fontSize: '1.5rem', // 24px
    fontWeight: 400
  },
  h5: {
    fontSize: '1.25rem', // 20px
    fontWeight: 300
  },
  h6: {
    fontSize: '1rem', // 16px
    fontWeight: 300
  },
  body1: {
    fontSize: '1rem', // 16px
    fontWeight: 400
  },
  body2: {
    fontSize: '0.875rem', // 14px
    fontWeight: 400
  }
};

const theme = createTheme({
  palette,
  // shadows,
  breakpoints,
  typography
});

export default theme;
