import { Box, Button, TextField } from '@mui/material';
import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { AppDispatch } from '../../../redux';
import { showSnackbar } from '../../../redux/slices/snackbarSlice';
import axiosInstance from '../../../utils/axiosConfig';

const ResetPasswordConfirm: React.FC = () => {
  const { uidb64, token } = useParams<{ uidb64: string; token: string }>(); // Extract uidb64 and token from URL
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    if (newPassword.length < 8) {
      setError('Password must be at least 8 characters long.');
      return;
    }

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    setIsSubmitting(true);

    try {
      const url = `/password/reset/confirm/${uidb64}/${token}/`;

      const response = await axiosInstance.post(url, {
        new_password: newPassword
      });

      if (response.status === 200) {
        dispatch(
          showSnackbar({
            message:
              response.data.detail ||
              'Password has been successfully reset! You can now log in with your new password.',
            severity: 'success'
          })
        );

        navigate('/login');
      }
    } catch (err) {
      const typedError = err as AxiosError<{
        non_field_errors?: string[];
        new_password?: string[];
      }>;

      if (typedError.response?.data?.non_field_errors) {
        // Handle invalid or expired token error
        const errorMessage = typedError.response.data.non_field_errors.join(', ');
        dispatch(
          showSnackbar({
            message: errorMessage || 'Invalid or expired token.',
            severity: 'error'
          })
        );
      } else if (typedError.response?.data?.new_password) {
        // Handle password-specific error
        const errorMessage = typedError.response.data.new_password.join(', ');
        dispatch(
          showSnackbar({
            message: errorMessage || 'Error resetting password.',
            severity: 'error'
          })
        );
      } else {
        // General error handling
        dispatch(
          showSnackbar({
            message:
              'An error occurred while resetting the password. Please try again later.',
            severity: 'error'
          })
        );
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        maxWidth: '500px',
        width: '100%',
        margin: '150px auto 30px',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px'
      }}
    >
      <TextField
        label="New Password"
        type="password"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
        error={!!error}
        helperText={error}
        variant="outlined"
        fullWidth
      />
      <TextField
        label="Confirm Password"
        type="password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        error={!!error}
        helperText={error}
        variant="outlined"
        fullWidth
      />

      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        disabled={isSubmitting}
      >
        {isSubmitting ? 'Resetting...' : 'Reset Password'}
      </Button>
    </Box>
  );
};

export default ResetPasswordConfirm;
