import { styled } from '@mui/material';

export const ModernBuildingContainer = styled('div')({
  width: '100%',
  height: '650px',
  position: 'relative',
  overflow: 'hidden',
  margin: '50px 0',

  '& .MuiBox-root': {
    padding: '0 !important'
  },

  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.05)'
  }
});

export const ModernBuildingOverflow = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: 'rgba(0, 0, 0, 0.15)',
  color: 'white',
  textAlign: 'start',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  textAlignLast: 'center'
});

export const ModernBuildingPicture = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover'
});

export const ModernBuildingHeading = styled('h2')({
  fontSize: '58px',
  margin: '10px'
});

export const ModernBuildingDescription = styled('h3')({
  fontSize: '30px',
  marginTop: '30px'
});

export const ModernBuildingButtonContainer = styled('div')({
  marginTop: '40px'
});
