import { Box, Button, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { CustomInput } from '../../components';
import { AppDispatch, RootState } from '../../redux';
import { registerUser } from '../../redux/actions/authActions';
import { showSnackbar } from '../../redux/slices/snackbarSlice';
import { RegisterFormData } from '../../redux/types';
import { AppRoutes } from '../../routes';

const RegisterPage: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const { loading, error } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();

  const [formData, setFormData] = useState<RegisterFormData>({
    userName: '',
    surname: '',
    email: '',
    phoneNumber: '',
    address: '',
    login: '',
    password: '',
    passwordConfirm: ''
  });

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await dispatch(registerUser(formData, navigate));
    } catch (e) {
      dispatch(showSnackbar({ message: error, severity: 'error' }));
    }
  };

  return (
    <Box sx={{ maxWidth: 500, margin: 'auto', padding: '100px 50px' }}>
      <Typography variant="h4" gutterBottom textAlign={'center'}>
        Register
      </Typography>

      <form onSubmit={handleSubmit}>
        <CustomInput
          label="Name"
          name="userName"
          value={formData.userName}
          onChange={handleInputChange}
        />
        <CustomInput
          label="Surname"
          name="surname"
          value={formData.surname}
          onChange={handleInputChange}
        />
        <CustomInput
          label="Email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          type="email"
        />
        <CustomInput
          label="Phone Number"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleInputChange}
        />
        <CustomInput
          label="Address"
          name="address"
          value={formData.address || ''}
          onChange={handleInputChange}
        />
        <CustomInput
          label="Login"
          name="login"
          value={formData.login || ''}
          onChange={handleInputChange}
        />
        <CustomInput
          label="Password"
          name="password"
          value={formData.password}
          onChange={handleInputChange}
          type="password"
        />
        <CustomInput
          label="Confirm Password"
          name="passwordConfirm"
          value={formData.passwordConfirm}
          onChange={handleInputChange}
          type="password"
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={loading}
          sx={{ marginTop: '20px' }}
        >
          {loading ? 'Loading...' : 'Register'}
        </Button>
      </form>

      <Box sx={{ textAlign: 'center', marginTop: '30px' }} color={'#1976d2'}>
        Have an account?
        <Button
          variant="text"
          component={Link}
          to={AppRoutes.REGISTER}
          sx={{
            textDecoration: 'underline',
            textTransform: 'none'
          }}
        >
          Sign In
        </Button>
      </Box>
    </Box>
  );
};

export default RegisterPage;
