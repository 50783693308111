import { Paper, styled } from '@mui/material';

export const WorkWithUsCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: '9px',
  boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.08)',
  '&:hover': {
    boxShadow: '4px 4px 6px rgba(0, 0, 0, 0.13)'
  }
}));
