interface AuthUser {
  name: string;
  email: string;
  username: string;
}

enum UserRoles {
  Admin = 'admin',
  User = 'user',
  Developer = 'developer'
}

type UserRole = keyof typeof UserRoles;
interface AuthInitialState {
  user: AuthUser | null;
  accessToken: string | null;
  refreshToken: string | null;
  isLoggedIn: boolean;
  error: string;
  loading: boolean;
}

interface AuthRegisterResponse {
  access: string;
  refresh: string;
  user: AuthUser;
}

interface UserAddResponse {
  detail: string;
  user: {
    access: string;
    refresh: string;
    user: AuthUser;
  };
}

interface RegisterFormData {
  userName: string;
  surname: string;
  email: string;
  phoneNumber: string;
  address?: string;
  login?: string;
  password: string;
  passwordConfirm: string;
  role?: string;
}

interface LoginFormData {
  email: string;
  password: string;
}

interface UserData {
  name: string;
  surname: string;
  userName: string;
  email: string;
  phoneNumber: string;
  address: string;
  id: string;
  role: UserRoles;
}

interface addUserActionData extends UserData {
  password: string;
  passwordConfirm: string;
}

interface UserGetDataResponse {
  address: string;
  email: string;
  id: string;
  is_editable: boolean;
  name: string;
  phone_number: string;
  surname: string;
  username: string;
  role: UserRoles;
}

export type {
  AuthInitialState,
  AuthRegisterResponse,
  AuthUser,
  LoginFormData,
  RegisterFormData,
  UserAddResponse,
  UserData,
  UserGetDataResponse,
  UserRole,
  addUserActionData
};

export { UserRoles };
