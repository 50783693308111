import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { restoreSession } from '../../redux/slices';

const useRestoreSession = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');

    if (accessToken && refreshToken) {
      dispatch(restoreSession({ accessToken, refreshToken }));
    }
  }, [dispatch]);
};

export default useRestoreSession;
