import { SxProps, TextField, TextFieldProps } from '@mui/material';
import React from 'react';

interface CustomInputProps
  extends Omit<
    TextFieldProps,
    'label' | 'name' | 'value' | 'onChange' | 'type' | 'required' | 'sx'
  > {
  label?: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  required?: boolean;
  sx?: SxProps;
}

const CustomInput: React.FC<CustomInputProps> = ({
  label,
  name,
  value,
  onChange,
  type = 'text',
  required = false,
  sx = { marginTop: '8px' },
  ...props
}) => {
  return (
    <TextField
      fullWidth
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      type={type}
      required={required}
      sx={sx}
      {...props}
    />
  );
};

export default CustomInput;
