import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { AccordionSummary, Typography } from '@mui/material';
import { FC, useState } from 'react';
import {
  FAQAccordion,
  FAQAccordionContainer,
  FAQAccordionDetails,
  FAQContainer,
  FAQDescription,
  FAQHeading,
  FAQSectionWrapper
} from './FAQ.styles';

const faqData = [
  {
    question: 'What is Real Estate?',
    answer:
      'Real estate is property consisting of land and the buildings on it, along with its natural resources.'
  },
  {
    question: 'How do I invest in real estate?',
    answer:
      'You can invest in real estate by purchasing properties, REITs, or engaging in real estate crowdfunding.'
  },
  {
    question: 'What is the role of a real estate agent?',
    answer:
      'A real estate agent assists in buying, selling, and renting properties, acting as an intermediary between the parties.'
  }
];

const FAQ: FC = () => {
  const [expanded, setExpanded] = useState<number | false>(false);

  const handleChange =
    (index: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? index : false);
    };

  return (
    <FAQSectionWrapper className="containerDesktop">
      <FAQContainer>
        <FAQHeading>Have More Questions?</FAQHeading>
        <FAQDescription>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eius in sunt totam
          pariatur?
        </FAQDescription>

        <FAQAccordionContainer>
          {faqData.map((item, index) => (
            <FAQAccordion
              expanded={expanded === index}
              onChange={handleChange(index)}
              key={index}
            >
              <AccordionSummary
                expandIcon={expanded === index ? <RemoveIcon /> : <AddIcon />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
                sx={{
                  backgroundColor: expanded === index ? '#f7f7f7' : '#fff',
                  borderBottom: expanded ? 'none' : '1px solid #ddd',
                  padding: '0 16px'
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  {item.question}
                </Typography>
              </AccordionSummary>

              <FAQAccordionDetails>
                <Typography>{item.answer}</Typography>
              </FAQAccordionDetails>
            </FAQAccordion>
          ))}
        </FAQAccordionContainer>
      </FAQContainer>
    </FAQSectionWrapper>
  );
};

export default FAQ;
