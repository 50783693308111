import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import MailIcon from '@mui/icons-material/Mail';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import {
  AppBar,
  Avatar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar
} from '@mui/material';
import { Outlet, To, useNavigate } from 'react-router-dom';
import { Logo } from '../../components';
import { AppRoutes, PageTitles } from '../../routes';
import { AccountAppBarContainer } from './AccountPage.styles';

const DRAWER_WIDTH = 240;

const ACCOUNT_MENU_LINKS = [
  { url: AppRoutes.ACCOUNT_DASHBOARD, name: PageTitles.ACCOUNT_DASHBOARD },
  { url: AppRoutes.ACCOUNT_FAVORITES, name: PageTitles.ACCOUNT_FAVORITES },
  { url: AppRoutes.ACCOUNT_HISTORY, name: PageTitles.ACCOUNT_HISTORY },
  { url: AppRoutes.ACCOUNT_PROFILE, name: PageTitles.ACCOUNT_PROFILE },
  { url: AppRoutes.ACCOUNT_ADD_USER, name: PageTitles.ACCOUNT_ADD_USER }
];

const AccountPage = () => {
  const navigate = useNavigate();

  const handleNavigation = (path: To) => {
    navigate(path);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ width: `calc(100% - ${DRAWER_WIDTH}px)`, ml: `${DRAWER_WIDTH}px` }}
      >
        <AccountAppBarContainer>
          <Toolbar>{PageTitles.ACCOUNT_DASHBOARD}</Toolbar>

          <CircleNotificationsIcon />
          <Avatar />
        </AccountAppBarContainer>
      </AppBar>
      <Drawer
        sx={{
          width: DRAWER_WIDTH,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: DRAWER_WIDTH,
            boxSizing: 'border-box'
          }
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar>
          <Logo />
        </Toolbar>

        <Box sx={{ overflow: 'auto' }}>
          <List>
            {ACCOUNT_MENU_LINKS.map(({ url, name }, index) => (
              <ListItem key={name} disablePadding>
                <ListItemButton onClick={() => handleNavigation(url)}>
                  <ListItemIcon>
                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                  </ListItemIcon>
                  <ListItemText primary={name} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>

          <Divider />

          <List>
            {['All mail', 'Trash', 'Spam'].map((text, index) => (
              <ListItem key={text} disablePadding>
                <ListItemButton
                  onClick={() =>
                    handleNavigation(`/account/${text.toLowerCase().replace(' ', '-')}`)
                  }
                >
                  <ListItemIcon>
                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
};

export default AccountPage;
