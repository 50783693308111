import { Accordion, AccordionDetails, styled } from '@mui/material';

export const FAQSectionWrapper = styled('div')({});

export const FAQContainer = styled('div')({
  margin: '50px 0'
});

export const FAQHeading = styled('h2')({
  fontWeight: 500,
  fontSize: '40px',
  lineHeight: '52px',
  textAlign: 'center'
});

export const FAQDescription = styled('h3')({
  fontSize: '16px',
  lineHeight: '26px',
  fontWeight: '400',
  marginTop: '20px',
  textAlign: 'center'
});

export const FAQAccordionContainer = styled('div')({
  maxWidth: '700px',
  margin: '40px auto 0'
});

export const FAQAccordion = styled(Accordion)({
  marginBottom: '10px',
  overflow: 'hidden',
  boxShadow: 'none'
});

export const FAQAccordionDetails = styled(AccordionDetails)({
  backgroundColor: '#f7f7f7',
  padding: '16px'
});
