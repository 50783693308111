import { Box } from '@mui/material';
import { FC } from 'react';
import { MapCameraChangedEvent, Map } from '@vis.gl/react-google-maps';
import { PoiMarkers } from './PoiMarkers';
import { useGoogleMap } from './useGoogleMap';
import FullScreenLoader from '../FullScreenLoader';

const GoogleMap: FC = () => {
  const { center, locations, isLoading } = useGoogleMap();
  
  if (isLoading) {
    return <FullScreenLoader />
  }
  return (
    <Box
      className="containerDesktop"
      sx={{
        maxWidth: "md",
        height: 600,
        textAlign: 'center',
        border: 0,
        padding: '50px 0',
        borderRadius: '15px'
      }}
    >
      <Map
        defaultZoom={5}
        defaultCenter={center}
        mapId="google_map_id"
        onCameraChanged={ (ev: MapCameraChangedEvent) =>
          console.log(process.env.REACT_APP_GOOGLE_MAPS_API_KEY, ev.detail)
        }>
          <PoiMarkers pois={locations} />
      </Map>
    </Box>
  );
};

export default GoogleMap;