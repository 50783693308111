import { ChatBubbleOutline as ChatBubbleOutlineIcon } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { useState } from 'react';

const ChatIcon = () => {
  const [isChatVisible, setChatVisible] = useState(true);

  const handleCloseChat = () => {
    setChatVisible(false);
  };

  return (
    <>
      {isChatVisible && (
        <Box
          id="chat-icon"
          sx={{
            position: 'fixed',
            bottom: 35,
            right: 35
          }}
        >
          <div
            onClick={handleCloseChat}
            style={{
              position: 'absolute',
              top: -17,
              right: -10,
              margin: 0,
              padding: '1px 4px',
              backgroundColor: '#ff2e2eb5',
              color: 'white',
              zIndex: 2
            }}
          >
            x
          </div>

          <IconButton
            color="primary"
            sx={{
              backgroundColor: 'white',
              '&:hover': {
                backgroundColor: '#e0e0e0'
              },
              boxShadow: 5
            }}
          >
            <ChatBubbleOutlineIcon />
          </IconButton>
        </Box>
      )}
    </>
  );
};

export default ChatIcon;
