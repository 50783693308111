import { Box, Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { CustomInput } from '../../components';
import { AppDispatch, RootState } from '../../redux';
import { loginUser } from '../../redux/actions/authActions';
import { LoginFormData } from '../../redux/types';
import { AppRoutes } from '../../routes';

const LoginPage: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const { loading } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();

  const [formData, setFormData] = useState<LoginFormData>({
    email: '',
    password: ''
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await dispatch(loginUser(formData, navigate));
  };

  return (
    <Box sx={{ maxWidth: 500, margin: 'auto', padding: '100px 50px' }}>
      <Typography variant="h4" gutterBottom textAlign={'center'}>
        Login
      </Typography>

      <div>61john@example.com</div>
      <div>NewPassword123!</div>

      <form onSubmit={handleSubmit}>
        <CustomInput
          label="Email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          type="email"
        />

        <CustomInput
          label="Password"
          name="password"
          value={formData.password}
          onChange={handleInputChange}
          type="password"
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={loading}
          sx={{ marginTop: '20px' }}
        >
          {loading ? 'Loading...' : 'Login'}
        </Button>
      </form>

      <div style={{ marginTop: '30px', textAlign: 'center' }}>
        <Button
          variant="text"
          sx={{ textDecoration: 'underline' }}
          color="primary"
          component={Link}
          to={AppRoutes.FORGOT_PASSWORD}
        >
          Forgot password?
        </Button>
      </div>

      <Box sx={{ textAlign: 'center', marginTop: '30px' }}>
        <Button variant="outlined" component={Link} to={AppRoutes.REGISTER}>
          Create an account
        </Button>
      </Box>
    </Box>
  );
};

export default LoginPage;
