import React from 'react';
import useRestoreSession from '../../utils/hooks';
import Snackbar from '../SnackBar';
import ChatIcon from './ChatIcon';
import MainContentBlock from './MainContent.styles';

const MainContent: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  useRestoreSession();

  return (
    <MainContentBlock>
      {children}

      <ChatIcon />

      <Snackbar />
    </MainContentBlock>
  );
};

export default MainContent;
