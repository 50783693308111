import {
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  LinkedIn as LinkedInIcon,
  Telegram as TelegramIcon
} from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { FC, ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Logo } from '../Header';
import {
  FooterBlock,
  FooterContainer,
  FooterCopyrightText,
  FooterLinksButton,
  FooterLinksButtonContainer,
  FooterLinksContainer,
  FooterLinksHeading,
  FooterLinksList,
  FooterSectionWrapper,
  FooterSocialContainer,
  FooterSocialIcons,
  FooterSocialLine,
  FooterSocialText
} from './Footer.styles';

interface SocialLink {
  icon: ReactNode;
  url: string;
}

type FooterLink = {
  name: string;
  links: { title: string; url: string }[];
};

const socialLinks: SocialLink[] = [
  { icon: <FacebookIcon />, url: 'https://www.facebook.com' },
  { icon: <TelegramIcon />, url: 'https://www.telegram.com' },
  { icon: <InstagramIcon />, url: 'https://www.instagram.com' },
  { icon: <LinkedInIcon />, url: 'https://www.linkedin.com' }
];

const productLinks: FooterLink = {
  name: 'Product',
  links: [
    { title: 'Smart Valuator', url: '/properties' },
    { title: 'Insight Dashboard', url: '/insight-dashboard' },
    { title: 'Instant Property Match', url: '/ai-property-match' },
    { title: 'AI Renovation Planner', url: '/ai-renovation-planner' },
    { title: 'AI Interior Designer', url: '/ai-interior-designer' }
  ]
};

const quickLinks: FooterLink = {
  name: 'Quick Links',
  links: [
    { title: 'How It Works', url: '/how-it-works' },
    { title: 'Properties', url: '/properties' },
    { title: 'For Investors', url: '/for-investors' },
    { title: 'FAQ', url: '/faq' },
    { title: 'Contact', url: '/contact' }
  ]
};

const companyLinks: FooterLink = {
  name: 'Company',
  links: [
    { title: 'About Us', url: '/about' },
    { title: 'Careers', url: '/careers' }
  ]
};

const legalLinks: FooterLink = {
  name: 'Legal',
  links: [
    { title: 'Terms of Service', url: '/terms-of-service' },
    { title: 'Privacy Policy', url: '/privacy-policy' },
    { title: 'Cookie Policy', url: '/cookie-policy' }
  ]
};

const contactLinks: FooterLink = {
  name: 'Contact Us',
  links: [
    { title: 'example@gmail.com', url: 'mailto:example@gmail.com' },
    { title: '(+1) 777-222-5577', url: 'tel:+17772225577' }
  ]
};

const footerLinks: FooterLink[] = [
  productLinks,
  quickLinks,
  companyLinks,
  legalLinks,
  contactLinks
];

const Footer: FC = () => {
  const location = useLocation();

  if (location.pathname.startsWith('/account')) {
    return null;
  }

  return (
    <FooterSectionWrapper>
      <FooterContainer>
        <FooterBlock className="footer-row-1">
          <Logo />

          <FooterSocialContainer>
            <FooterSocialText>Follow Us</FooterSocialText>

            <FooterSocialIcons>
              {socialLinks.map((link, index) => (
                <IconButton
                  key={index}
                  component="a"
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    color: '#dfdfdf',
                    '&:hover': {
                      color: '#1976d2'
                    },
                    border: '1px solid',
                    borderRadius: '50%',
                    padding: '5px'
                  }}
                >
                  {link.icon}
                </IconButton>
              ))}
            </FooterSocialIcons>
          </FooterSocialContainer>
        </FooterBlock>

        <FooterSocialLine />

        <FooterBlock>
          <FooterLinksList>
            {footerLinks.map((footerLink) => (
              <FooterLinksContainer key={footerLink.name}>
                <FooterLinksHeading>{footerLink.name}</FooterLinksHeading>

                <FooterLinksButtonContainer>
                  {footerLink.links.map((link) => {
                    const isExternal =
                      link.url.startsWith('mailto:') || link.url.startsWith('tel:');

                    return (
                      <div key={link.title}>
                        {isExternal ? (
                          <FooterLinksButton component="a" href={link.url}>
                            {link.title}
                          </FooterLinksButton>
                        ) : (
                          <FooterLinksButton component={Link} to={link.url}>
                            {link.title}
                          </FooterLinksButton>
                        )}
                      </div>
                    );
                  })}
                </FooterLinksButtonContainer>
              </FooterLinksContainer>
            ))}
          </FooterLinksList>
        </FooterBlock>

        <FooterSocialLine />

        <FooterBlock>
          <FooterCopyrightText>Copyright © 2024. Real Estate</FooterCopyrightText>
        </FooterBlock>
      </FooterContainer>
    </FooterSectionWrapper>
  );
};

export default Footer;
