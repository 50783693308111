import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography
} from '@mui/material';
import { ChangeEvent, FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CustomInput } from '../../../components';
import { AppDispatch } from '../../../redux';
import { addUserAction } from '../../../redux/actions/authActions';
import { addUserActionData, UserRoles } from '../../../redux/types';

const initialUserData: addUserActionData = {
  name: '',
  surname: '',
  userName: '',
  email: '',
  phoneNumber: '',
  address: '',
  id: '',
  password: '',
  passwordConfirm: '',
  role: UserRoles.User
};

const AccountAddUser: FC = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState<addUserActionData>({ ...initialUserData });
  const [formErrors, setFormErrors] = useState<{ [key: string]: string[] }>({});
  const dispatch = useDispatch<AppDispatch>();

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleCancel = () => {
    setIsEditing(false);
    setFormData(initialUserData);
    setFormErrors({});
  };

  const handleSave = async () => {
    dispatch(
      addUserAction(formData, setIsEditing, setFormData, setFormErrors, initialUserData)
    );
  };

  const handleRoleChange = (event: SelectChangeEvent<UserRoles>) => {
    setFormData({
      ...formData,
      role: event.target.value as UserRoles
    });
  };

  const renderInput = useCallback(
    (
      label: string,
      name: keyof addUserActionData,
      value: string,
      type = 'text',
      disabled = false
    ) => (
      <Box sx={{ marginTop: '15px' }}>
        <Typography>{label}</Typography>

        <CustomInput
          name={name}
          value={value}
          variant="standard"
          onChange={handleInputChange}
          disabled={disabled}
          type={type}
          sx={{
            '& .Mui-disabled.MuiInputBase-root::before': {
              borderBottomStyle: disabled ? 'none' : 'none'
            }
          }}
        />

        {formErrors[name]?.map((error, index) => (
          <Typography key={index} color="error" variant="caption">
            {error}
          </Typography>
        ))}
      </Box>
    ),
    [formData, isEditing, formErrors]
  );

  return (
    <Box sx={{ maxWidth: 500, margin: 'auto', padding: '50px' }}>
      <Typography variant="h4" gutterBottom>
        Add User
      </Typography>

      <Box sx={{ marginTop: '20px' }}>
        <div>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Create
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleCancel}
            sx={{ ml: 2 }}
          >
            Cancel
          </Button>
        </div>
      </Box>

      {renderInput('Name', 'name', formData.name)}
      {renderInput('Surname', 'surname', formData.surname)}
      {renderInput('Address', 'address', formData.address)}
      {renderInput('Phone Number', 'phoneNumber', formData.phoneNumber)}
      {renderInput('User Name', 'userName', formData.userName)}
      {renderInput('Email', 'email', formData.email, 'email')}

      <Box sx={{ marginTop: '15px' }}>
        <FormControl fullWidth variant="standard">
          <InputLabel id="role-label">Role</InputLabel>

          <Select labelId="role-label" value={formData.role} onChange={handleRoleChange}>
            <MenuItem value={UserRoles.User}>User</MenuItem>
            <MenuItem value={UserRoles.Developer}>Developer</MenuItem>
            <MenuItem value={UserRoles.Admin}>Admin</MenuItem>
          </Select>

          {formErrors['role']?.map((error, index) => (
            <Typography key={index} color="error" variant="caption">
              {error}
            </Typography>
          ))}
        </FormControl>
      </Box>

      {renderInput('Password', 'password', formData.password, 'password')}
      {renderInput(
        'Confirm Password',
        'passwordConfirm',
        formData.passwordConfirm,
        'password'
      )}
    </Box>
  );
};

export default AccountAddUser;
