import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button } from '@mui/material';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import {
  AchievementPicture,
  AchievementsButtonWrapper,
  AchievementsContainer,
  AchievementsContainerBlock,
  AchievementsDescription,
  AchievementsDigitsDescription,
  AchievementsDigitsHeading,
  AchievementsDigitsItem,
  AchievementsDigitsItemWrapper,
  AchievementsHeading,
  AchievementsSectionWrapper
} from './Achievements.styles';

const achievementsDigitsList = [
  { count: '$18M', description: 'Owned From Properties' },
  { count: '15K+', description: 'Properties For Sale' },
  { count: '26K+', description: 'Properties For Buy' },
  { count: '890', description: 'Daily Completer Transactions' }
];

const Achievements: FC = () => {
  return (
    <AchievementsSectionWrapper className="containerDesktop">
      <AchievementsContainer>
        <AchievementsContainerBlock className="achievements-block-1">
          <AchievementsHeading>
            We Use Real Estate to Show Our Appreciation of The World
          </AchievementsHeading>

          <AchievementsDescription>
            Pellentesque egestas elementum egestas faucibus sem. Velit nunc egestas ut
            morbi. Leo diam diam nibh eget fermentum massa pretium. Mi mauris nulla ac
            dictum ut mauris non.
          </AchievementsDescription>

          <AchievementsDigitsItemWrapper>
            {achievementsDigitsList.map(({ count, description }, index) => (
              <AchievementsDigitsItem key={`achievement-digit-item-${index}`}>
                <AchievementsDigitsHeading>{count}</AchievementsDigitsHeading>
                <AchievementsDigitsDescription>
                  {description}
                </AchievementsDigitsDescription>
              </AchievementsDigitsItem>
            ))}
          </AchievementsDigitsItemWrapper>

          <AchievementsButtonWrapper>
            <Button
              variant="contained"
              color="primary"
              endIcon={<ArrowForwardIcon />}
              component={Link}
              to="/properties"
            >
              Learn More
            </Button>
          </AchievementsButtonWrapper>
        </AchievementsContainerBlock>

        <AchievementsContainerBlock className="achievements-block-2">
          <AchievementPicture src="images/Modern_building_2.jpg" alt="Modern building" />
        </AchievementsContainerBlock>
      </AchievementsContainer>
    </AchievementsSectionWrapper>
  );
};

export default Achievements;
