import i18n from 'i18next';
import Cookies from 'js-cookie';
import { initReactI18next } from 'react-i18next';
import { LANGUAGES, LANGUAGE_COOKIE_KEY } from '../../constants';
import chineseTranslation from './locales/ch.json';
import englishTranslation from './locales/en.json';
import frenchTranslation from './locales/fr.json';

const savedLanguage = Cookies.get(LANGUAGE_COOKIE_KEY) || LANGUAGES.ENGLISH;

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: englishTranslation
    },
    ch: {
      translation: chineseTranslation
    },
    fr: {
      translation: frenchTranslation
    }
  },
  lng: savedLanguage, // initial lang
  fallbackLng: LANGUAGES.ENGLISH, //default lang
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
