import { AxiosError, isAxiosError } from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { AppRoutes } from '../../routes';
import { getUserIdFromToken } from '../../utils';
import axiosInstance from '../../utils/axiosConfig';
import {
  authError,
  hideLoading,
  logout,
  setLoading,
  setTokens,
  setUser,
  showLoading
} from '../slices/authSlice';
import { showSnackbar } from '../slices/snackbarSlice';
import { AppDispatch } from '../store';
import {
  addUserActionData,
  AuthRegisterResponse,
  RegisterFormData,
  UserAddResponse,
  UserData,
  UserGetDataResponse,
  UserRoles
} from '../types';

export const registerUser =
  (formData: RegisterFormData, navigate: NavigateFunction) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(setLoading(true));

      const response = await axiosInstance.post<AuthRegisterResponse>('/register/', {
        name: formData.userName,
        surname: formData.surname,
        email: formData.email,
        phone_number: formData.phoneNumber,
        address: formData.address,
        username: formData.userName,
        password: formData.password,
        password_confirm: formData.passwordConfirm
      });

      const { access: accessToken, refresh: refreshToken, user } = response.data;

      dispatch(setTokens({ accessToken, refreshToken }));
      dispatch(setUser(user));

      dispatch(
        showSnackbar({
          message: 'Registered successfully!',
          severity: 'success'
        })
      );

      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);

      navigate(AppRoutes.HOME);
    } catch (error) {
      showErrors(dispatch, error);
    } finally {
      dispatch(setLoading(false));
    }
  };

export const loginUser =
  (formData: { email: string; password: string }, navigate: NavigateFunction) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(setLoading(true));

      const response = await axiosInstance.post<AuthRegisterResponse>(
        '/login/',
        formData
      );

      const { access: accessToken, refresh: refreshToken, user } = response.data;

      dispatch(setTokens({ accessToken, refreshToken }));
      dispatch(setUser(user));

      dispatch(
        showSnackbar({
          message: 'Logged in successfully!',
          severity: 'success'
        })
      );

      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);

      navigate(AppRoutes.HOME);
    } catch (error) {
      showErrors(dispatch, error);
    } finally {
      dispatch(setLoading(false));
    }
  };

// todo
function showErrors(dispatch: AppDispatch, error: unknown) {
  const typedError = error as AxiosError<{ [key: string]: string[] }>;

  const errorMessages: string[] = [];

  if (typedError.response?.data) {
    const errorData = typedError.response.data;

    for (const key in errorData) {
      if (Object.prototype.hasOwnProperty.call(errorData, key)) {
        const fieldErrors = errorData[key];
        errorMessages.push(...fieldErrors);
      }
    }
  }

  const finalErrorMessage =
    errorMessages.length > 0 ? errorMessages.join(', ') : typedError.message;

  dispatch(authError({ err: finalErrorMessage }));

  dispatch(
    showSnackbar({
      message: finalErrorMessage,
      severity: 'error'
    })
  );
}

export const editUserAction =
  (
    formData: UserData,
    setFormErrors: Dispatch<
      SetStateAction<{
        [key: string]: string[];
      }>
    >,
    setInitialUser: Dispatch<SetStateAction<UserData | null>>,
    setIsEditing: Dispatch<SetStateAction<boolean>>
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch(showLoading());
    const userId = getUserIdFromToken();

    try {
      await axiosInstance.put(`/user/${userId}/`, {
        ...formData,
        phone_number: formData.phoneNumber,
        username: formData.userName
      });

      setInitialUser(formData);
      setIsEditing(false);
      dispatch(
        showSnackbar({ message: 'User data updated successfully', severity: 'success' })
      );
      setFormErrors({});
    } catch (error) {
      if (isAxiosError(error) && error.response) {
        const serverErrors = error.response.data;
        const transformedErrors: Record<string, string[]> = {};

        Object.keys(serverErrors).forEach((key) => {
          let transformedKey = key;

          if (key === 'phone_number') {
            transformedKey = 'phoneNumber';
          } else if (key === 'username') {
            transformedKey = 'userName';
          }

          transformedErrors[transformedKey] = serverErrors[key];
        });

        setFormErrors(transformedErrors);
      } else {
        dispatch(
          showSnackbar({ message: 'Error updating user data', severity: 'error' })
        );
      }
    } finally {
      dispatch(hideLoading());
    }
  };

export const getUserAction =
  (
    setInitialUser: Dispatch<SetStateAction<UserData | null>>,
    setFormData: Dispatch<SetStateAction<UserData>>
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(showLoading());

      const userId = getUserIdFromToken();

      const response = await axiosInstance.get<UserGetDataResponse>(`/user/${userId}/`);

      const {
        address,
        email,
        id,
        is_editable,
        name,
        phone_number,
        role,
        surname,
        username
      } = response.data;

      const responseUser = {
        name,
        surname,
        userName: username,
        email,
        phoneNumber: phone_number,
        address,
        id,
        role
      };

      setInitialUser(responseUser);
      setFormData(responseUser);
    } catch (error) {
      dispatch(showSnackbar({ message: 'Error fetching user data', severity: 'error' }));
    } finally {
      dispatch(hideLoading());
    }
  };

interface initialAddUserData {
  name: string;
  surname: string;
  userName: string;
  email: string;
  phoneNumber: string;
  address: string;
  id: string;
  role: UserRoles;
  password: string;
  passwordConfirm: string;
}

export const addUserAction =
  (
    formData: RegisterFormData,
    setIsEditing: Dispatch<SetStateAction<boolean>>,
    setFormData: Dispatch<SetStateAction<addUserActionData>>,
    setFormErrors: Dispatch<
      SetStateAction<{
        [key: string]: string[];
      }>
    >,
    initialUserData: initialAddUserData
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch(showLoading());

    try {
      await axiosInstance.post<UserAddResponse>(`/users/`, {
        name: formData.userName,
        surname: formData.surname,
        email: formData.email,
        phone_number: formData.phoneNumber,
        address: formData.address,
        username: formData.userName,
        role: formData.role,
        password: formData.password,
        password_confirm: formData.passwordConfirm
      });

      setIsEditing(false);
      dispatch(
        showSnackbar({ message: 'User data updated successfully', severity: 'success' })
      );
      setFormErrors({});
      setFormData(initialUserData);
    } catch (error) {
      if (isAxiosError(error) && error.response) {
        const serverErrors = error.response.data;
        const transformedErrors: Record<string, string[]> = {};

        Object.keys(serverErrors).forEach((key) => {
          let transformedKey = key;

          if (key === 'phone_number') {
            transformedKey = 'phoneNumber';
          } else if (key === 'username') {
            transformedKey = 'userName';
          } else if (key === 'password_confirm') {
            transformedKey = 'passwordConfirm';
          }

          transformedErrors[transformedKey] = serverErrors[key];
        });

        setFormErrors(transformedErrors);
      } else {
        dispatch(
          showSnackbar({ message: 'Error updating user data', severity: 'error' })
        );
      }
    } finally {
      dispatch(hideLoading());
    }
  };

export const deleteUserAction =
  (navigate: NavigateFunction) => async (dispatch: AppDispatch) => {
    const userId = getUserIdFromToken();

    dispatch(showLoading());

    try {
      await axiosInstance.delete(`/user-del/${userId}/`);

      dispatch(
        showSnackbar({ message: 'User deleted successfully', severity: 'success' })
      );
      dispatch(logout());

      navigate(AppRoutes.HOME);
    } catch (error) {
      dispatch(showSnackbar({ message: 'Error deleting user', severity: 'error' }));
    } finally {
      dispatch(hideLoading());
    }
  };
