import CloseIcon from '@mui/icons-material/Close';
import { Alert, Box, IconButton, LinearProgress, Snackbar } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux';
import { closeSnackbar } from '../../redux/slices/snackbarSlice';

const SNACKBAR_DURATION = 4000;

const GlobalSnackbar: FC = () => {
  const dispatch = useDispatch();
  const { open, message, severity } = useSelector((state: RootState) => state.snackbar);

  const [progress, setProgress] = useState(100);

  useEffect(() => {
    if (open) {
      setProgress(100);

      const interval = setInterval(() => {
        setProgress((prev) => (prev > 0 ? prev - 1 : 0));
      }, SNACKBAR_DURATION / 100);

      return () => {
        clearInterval(interval);
      };
    }
  }, [open]);

  const handleClose = () => {
    dispatch(closeSnackbar());
  };

  const action = (
    <>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <Snackbar
      id="snackBar"
      open={open}
      autoHideDuration={SNACKBAR_DURATION}
      onClose={(event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        handleClose();
      }}
      message={message}
      action={action}
    >
      <Box sx={{ width: '100%' }}>
        <Alert
          onClose={handleClose}
          variant="filled"
          sx={{ width: '100%' }}
          severity={severity}
        >
          {message}
        </Alert>

        <LinearProgress
          variant="determinate"
          value={progress}
          id="progress-snack"
          sx={{
            height: 4,
            marginTop: '-4px',
            borderRadius: '5px',
            bgcolor: 'transparent',
            '& .MuiLinearProgress-bar': {
              bgcolor:
                severity === 'success' ? 'green' : severity === 'error' ? 'red' : 'blue'
            }
          }}
        />
      </Box>
    </Snackbar>
  );
};

export default GlobalSnackbar;
