import { Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { FC, useState } from 'react';

const countriesList = [
  { title: 'Afghanistan' },
  { title: 'Albania' },
  { title: 'Algeria' },
  { title: 'Andorra' },
  { title: 'Angola' },
  { title: 'Antigua and Barbuda' },
  { title: 'Argentina' },
  { title: 'Armenia' },
  { title: 'Australia' },
  { title: 'Austria' },
  { title: 'Azerbaijan' },
  { title: 'Bahamas' },
  { title: 'Bahrain' },
  { title: 'Bangladesh' },
  { title: 'Barbados' },
  { title: 'Belarus' },
  { title: 'Belgium' },
  { title: 'Belize' },
  { title: 'Benin' },
  { title: 'Bhutan' },
  { title: 'Bolivia' },
  { title: 'Bosnia and Herzegovina' },
  { title: 'Botswana' },
  { title: 'Brazil' },
  { title: 'Brunei' },
  { title: 'Bulgaria' },
  { title: 'Burkina Faso' },
  { title: 'Burundi' },
  { title: 'Cabo Verde' },
  { title: 'Cambodia' },
  { title: 'Cameroon' },
  { title: 'Canada' },
  { title: 'Central African Republic' },
  { title: 'Chad' },
  { title: 'Chile' },
  { title: 'China' },
  { title: 'Colombia' },
  { title: 'Comoros' },
  { title: 'Congo (Congo-Brazzaville)' },
  { title: 'Costa Rica' },
  { title: 'Croatia' },
  { title: 'Cuba' },
  { title: 'Cyprus' },
  { title: 'Czech Republic' },
  { title: 'Democratic Republic of the Congo' },
  { title: 'Denmark' },
  { title: 'Djibouti' },
  { title: 'Dominica' },
  { title: 'Dominican Republic' },
  { title: 'Ecuador' },
  { title: 'Egypt' },
  { title: 'El Salvador' },
  { title: 'Equatorial Guinea' },
  { title: 'Eritrea' },
  { title: 'Estonia' },
  { title: 'Eswatini' },
  { title: 'Ethiopia' },
  { title: 'Fiji' },
  { title: 'Finland' },
  { title: 'France' },
  { title: 'Gabon' },
  { title: 'Gambia' },
  { title: 'Georgia' },
  { title: 'Germany' },
  { title: 'Ghana' },
  { title: 'Greece' },
  { title: 'Grenada' },
  { title: 'Guatemala' },
  { title: 'Guinea' },
  { title: 'Guinea-Bissau' },
  { title: 'Guyana' },
  { title: 'Haiti' },
  { title: 'Honduras' }
];

interface Country {
  title: string;
  year?: number;
}

function sleep(duration: number): Promise<void> {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
}

export function AsynchronousSearch() {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly Country[]>([]);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState<Country | null>(null);

  const handleOpen = () => {
    setOpen(true);
    (async () => {
      setLoading(true);
      await sleep(1e3); // For demo purposes.
      setLoading(false);

      setOptions([...countriesList]);
    })();
  };

  const handleClose = () => {
    setOpen(false);
    setOptions([]);
  };

  const handleClear = () => {
    setValue(null);
  };

  return (
    <Autocomplete
      sx={{ width: '80%', margin: '0 auto' }}
      open={open}
      value={value}
      onOpen={handleOpen}
      onClose={handleClose}
      onChange={(_, newValue) => setValue(newValue)} // Set a new value
      isOptionEqualToValue={(option, value) => option.title === value.title}
      getOptionLabel={(option) => option.title}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Enter a country name"
          sx={{
            '& fieldset': {
              // input
              borderRadius: '30px'
            },
            '& .MuiInputLabel-root': {
              // label
              color: 'blue',
              fontSize: '1.2rem',
              fontWeight: 'semibold'
            }
          }}
          slotProps={{
            input: {
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }
          }}
        />
      )}
    />
  );
}

const SearchHome: FC = () => {
  return (
    <Box className="containerDesktop" sx={{ margin: '100px auto 50px auto' }}>
      <AsynchronousSearch />
    </Box>
  );
};

export default SearchHome;
