import { Box, CardMedia, Typography } from '@mui/material';
import { FC } from 'react';
import {
  NeighborhoodCard,
  NeighborhoodCardContainer,
  NeighborhoodCardDescription,
  NeighborhoodCardTitle,
  NeighborhoodCardsList
} from './Neighborhoods.styles';

const neighborhoodsList = [
  {
    title: 'Downtown Vancouver',
    propertiesCount: 2,
    imageUrl: 'images/Vancouver.jpg'
  },
  {
    title: 'Coquitlam',
    propertiesCount: 10,
    imageUrl: 'images/Coquitlam.jpg'
  },
  {
    title: 'Burnaby',
    propertiesCount: 3,
    imageUrl: 'images/Burnaby.jpg'
  },
  {
    title: 'New Westminster',
    propertiesCount: 5,
    imageUrl: 'images/New_Westminster.jpg'
  },
  {
    title: 'Richmond',
    propertiesCount: 3,
    imageUrl: 'images/Richmond.jpg'
  }
];

const Neighborhoods: FC = () => {
  return (
    <div className="containerDesktop sectionPadding">
      <Box sx={{ padding: '40px 20px', textAlign: 'center' }}>
        <Typography variant="h2" sx={{ fontWeight: 500, marginBottom: '15px' }}>
          Neighborhoods
        </Typography>

        <Typography variant="body1" color="text.secondary" paragraph>
          Neighborhoods in Vancouver
        </Typography>

        <NeighborhoodCardsList>
          {neighborhoodsList.map((neighborhood, index) => (
            <NeighborhoodCard key={index}>
              <CardMedia
                component="img"
                height="100%"
                image={neighborhood.imageUrl}
                alt={neighborhood.title}
              />
              <NeighborhoodCardContainer>
                <NeighborhoodCardTitle>{neighborhood.title}</NeighborhoodCardTitle>
                <NeighborhoodCardDescription>
                  {neighborhood.propertiesCount} Properties
                </NeighborhoodCardDescription>
              </NeighborhoodCardContainer>
            </NeighborhoodCard>
          ))}
        </NeighborhoodCardsList>
      </Box>
    </div>
  );
};

export default Neighborhoods;
