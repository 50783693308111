import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EmailIcon from '@mui/icons-material/Email';
import { Button, InputAdornment, TextField } from '@mui/material';
import { FC } from 'react';
import {
  StayUpToDateContainer,
  StayUpToDateDescription,
  StayUpToDateHeading,
  StayUpToDateIcon,
  StayUpToDateIconContainer,
  StayUpToDateSearch,
  StayUpToDateSearchContainer
} from './StayUpToDate.styles';

const InputWithSendButton = () => {
  return (
    <TextField
      sx={{
        width: '60%',
        borderRadius: '15px'
      }}
      variant="outlined"
      placeholder="Your e-mail"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Button
              variant="text"
              sx={{
                color: '#1976d2',
                paddingRight: '8px',
                textTransform: 'none'
              }}
              endIcon={<ArrowForwardIcon />}
            >
              Subscribe
            </Button>
          </InputAdornment>
        )
      }}
    />
  );
};

const StayUpToDate: FC = () => {
  return (
    <StayUpToDateContainer className="containerDesktop">
      <StayUpToDateIconContainer>
        <StayUpToDateIcon>
          <EmailIcon />
        </StayUpToDateIcon>
      </StayUpToDateIconContainer>

      <StayUpToDateHeading>Stay Up To Date</StayUpToDateHeading>

      <StayUpToDateDescription>
        Subscribe to out newsletter to receive out weekly feed.
      </StayUpToDateDescription>

      <StayUpToDateSearchContainer>
        <StayUpToDateSearch>
          <InputWithSendButton />
        </StayUpToDateSearch>
      </StayUpToDateSearchContainer>
    </StayUpToDateContainer>
  );
};

export default StayUpToDate;
