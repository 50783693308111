import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button } from '@mui/material';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import {
  ModernBuildingButtonContainer,
  ModernBuildingContainer,
  ModernBuildingDescription,
  ModernBuildingHeading,
  ModernBuildingOverflow,
  ModernBuildingPicture
} from './ModernBuilding.styles';

const ModernBuilding: FC = () => {
  return (
    <ModernBuildingContainer>
      <ModernBuildingPicture src="images/Modern_building.jpg" alt="Modern building" />
      <ModernBuildingOverflow>
        <ModernBuildingHeading>
          Discover a place you&apos;ll love to live
        </ModernBuildingHeading>

        <ModernBuildingDescription>
          Find your dream home and create successful <br /> investments that will grow
          over time.
        </ModernBuildingDescription>

        <ModernBuildingButtonContainer>
          <Button
            variant="contained"
            color="primary"
            endIcon={<ArrowForwardIcon />}
            component={Link}
            to="/properties"
          >
            View Properties
          </Button>
        </ModernBuildingButtonContainer>
      </ModernBuildingOverflow>
    </ModernBuildingContainer>
  );
};

export default ModernBuilding;
