import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AuthInitialState, AuthRegisterResponse } from '../types';

const initialState: AuthInitialState = {
  user: null,
  accessToken: null,
  refreshToken: null,
  isLoggedIn: false,
  error: '',
  loading: false
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    showLoading: (state) => {
      state.loading = true;
    },
    hideLoading: (state) => {
      state.loading = false;
    },
    setTokens: (
      state,
      action: PayloadAction<{ accessToken: string; refreshToken: string }>
    ) => {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.isLoggedIn = true;

      localStorage.setItem('accessToken', action.payload.accessToken);
      localStorage.setItem('refreshToken', action.payload.refreshToken);
    },
    clearTokens(state) {
      state.accessToken = null;
      state.refreshToken = null;
      state.isLoggedIn = false;

      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
    },
    setUser: (state, action: PayloadAction<AuthRegisterResponse['user']>) => {
      state.user = action.payload;
      state.isLoggedIn = true;
    },
    authError: (state, action: PayloadAction<{ err: string }>) => {
      state.error = action.payload.err;
    },
    logout: (state) => {
      state.accessToken = null;
      state.refreshToken = null;
      state.user = null;
      state.isLoggedIn = false;

      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
    },
    restoreSession(
      state,
      action: PayloadAction<{ accessToken: string; refreshToken: string }>
    ) {
      state.isLoggedIn = true;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
    }
  }
});

export const {
  setTokens,
  setLoading,
  clearTokens,
  setUser,
  logout,
  restoreSession,
  authError,
  showLoading,
  hideLoading
} = authSlice.actions;

export default authSlice.reducer;
