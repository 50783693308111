import { MenuItem, Select, styled } from '@mui/material';
import { Language } from '../../../constants';

const StyledSelect = styled(Select)<{ value: Language }>({
  backgroundColor: '#f0f0f0',
  borderRadius: '6px',
  padding: 0,
  '& .MuiSelect-icon': {
    color: '#000'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#000'
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#3f51b5'
  }
});

const StyledMenuItem = styled(MenuItem)({
  '&:hover': {
    backgroundColor: '#fed7a8'
  }
});

export { StyledMenuItem, StyledSelect };
