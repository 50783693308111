import { Box } from '@mui/material';
import { APIProvider } from '@vis.gl/react-google-maps';
import { FC } from 'react';
import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { Footer, FullScreenLoader, Header, MainContent } from './components';
import './config/languages/i18n';
import {
  AboutPage,
  AccountAddUser,
  AccountDashboardPage,
  AccountFavoritesPage,
  AccountPage,
  AccountProfilePage,
  ContactPage,
  DevelopersPage,
  ForgotPasswordPage,
  HomePage,
  HowItWorksPage,
  InvestorsPage,
  LoginPage,
  PropertiesPage,
  RegisterPage,
  ResetPasswordConfirmPage
} from './pages';
import store from './redux';
import { AppRoutes } from './routes';

const AccountHistoryPage = () => {
  return <div>Your History Items</div>;
};

const App: FC = () => {
  return (
    <Provider store={store}>
      <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''}>
        <FullScreenLoader />

        <Router>
          <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Header />

            <MainContent>
              <Routes>
                <Route path={AppRoutes.HOME} element={<HomePage />} />
                <Route path={AppRoutes.PROPERTIES} element={<PropertiesPage />} />
                <Route path={AppRoutes.ABOUT} element={<AboutPage />} />
                <Route path={AppRoutes.FOR_INVESTORS} element={<InvestorsPage />} />
                <Route path={AppRoutes.FOR_DEVELOPERS} element={<DevelopersPage />} />
                <Route path={AppRoutes.HOW_IT_WORKS} element={<HowItWorksPage />} />
                <Route path={AppRoutes.CONTACT} element={<ContactPage />} />

                {/* Account */}
                <Route path={AppRoutes.ACCOUNT} element={<AccountPage />}>
                  <Route
                    path={AppRoutes.ACCOUNT_DASHBOARD}
                    element={<AccountDashboardPage />}
                  />
                  <Route
                    path={AppRoutes.ACCOUNT_FAVORITES}
                    element={<AccountFavoritesPage />}
                  />
                  <Route
                    path={AppRoutes.ACCOUNT_HISTORY}
                    element={<AccountHistoryPage />}
                  />
                  <Route
                    path={AppRoutes.ACCOUNT_PROFILE}
                    element={<AccountProfilePage />}
                  />
                  <Route path={AppRoutes.ACCOUNT_ADD_USER} element={<AccountAddUser />} />
                </Route>

                <Route
                  path={AppRoutes.FORGOT_PASSWORD}
                  element={<ForgotPasswordPage />}
                />
                <Route
                  path={`${AppRoutes.CONFIRM_PASSWORD}/:uidb64/:token/`}
                  element={<ResetPasswordConfirmPage />}
                />

                <Route path={AppRoutes.AUTH} element={<LoginPage />} />
                <Route path={AppRoutes.REGISTER} element={<RegisterPage />} />
              </Routes>
            </MainContent>

            <Footer />
          </Box>
        </Router>
      </APIProvider>
    </Provider>
  );
};

export default App;
