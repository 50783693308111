import { styled } from '@mui/material';

export const NeighborhoodCardsList = styled('div')({
  display: 'flex',
  justifyContent: 'space-between'
});

export const NeighborhoodCard = styled('div')({
  borderRadius: '9px',
  overflow: 'hidden',
  boxShadow: '3px 3px 5px rgba(0, 0, 0, 0.08)',

  position: 'relative',
  // width: '300px',
  height: '250px',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  margin: '20px',
  transition: 'transform 0.3s ease',

  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.25)'
  },

  '&:hover': {
    transform: 'scale(1.035)',
    cursor: 'pointer',
    '>div': {
      background: 'rgba(0, 0, 0, 0.25)'
    },

    '&::before': {
      backgroundColor: 'rgba(0, 0, 0, 0)'
    }
  }
});

export const NeighborhoodCardContainer = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: 'rgba(0, 0, 0, 0.15)',
  color: 'white',
  textAlign: 'start'
});

export const NeighborhoodCardTitle = styled('h4')({
  margin: '10px 20px'
});

export const NeighborhoodCardDescription = styled('h5')({
  margin: '10px 20px'
});
