import { AppBar, Box, Button, Toolbar, useTheme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { RootState } from '../../redux';
import { AppRoutes, PageTitles } from '../../routes';
import AvatarMenu from './AvatarMenu';
import LanguageSelector from './LanguageSelector';

const headerLinks = [
  { title: PageTitles.PROPERTIES, path: AppRoutes.PROPERTIES },
  { title: PageTitles.FOR_INVESTORS, path: AppRoutes.FOR_INVESTORS },
  { title: PageTitles.FOR_DEVELOPERS, path: AppRoutes.FOR_DEVELOPERS },
  { title: PageTitles.HOW_IT_WORKS, path: AppRoutes.HOW_IT_WORKS },
  { title: PageTitles.ABOUT, path: AppRoutes.ABOUT },
  { title: PageTitles.CONTACT, path: AppRoutes.CONTACT }
];

export const Logo: FC = () => {
  return (
    <Button component={Link} to="/">
      <svg width="120" viewBox="0 0 180 30" xmlns="http://www.w3.org/2000/svg">
        <text x="0" y="20" fill="orange" fontSize="30" fontWeight="bold">
          Real Estate
        </text>
      </svg>
    </Button>
  );
};

const Header: FC = () => {
  const theme = useTheme();
  const location = useLocation();
  const { t } = useTranslation();
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);

  const [showAccount, setShowAccount] = useState(false);

  useEffect(() => {
    setShowAccount(isLoggedIn);
  }, [isLoggedIn]);

  if (location.pathname.startsWith('/account')) {
    return null;
  }

  return (
    <AppBar position="fixed" color="transparent" elevation={0}>
      <Toolbar
        className="containerDesktop1"
        sx={{ minHeight: '20px', width: '90%', margin: '0 auto' }}
      >
        <Box
          sx={{
            padding: '0 25px',
            marginTop: '20px',
            display: 'flex',
            flex: '1',
            borderRadius: '10px',
            boxShadow: theme.shadows,
            alignItems: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.55)',
            backdropFilter: 'blur(5px)'
          }}
        >
          <Logo />

          <Box sx={{ margin: '0 auto' }}>
            {headerLinks.map((link) => (
              <Button key={link.title} color={'primary'} component={Link} to={link.path}>
                {t(link.title)}
              </Button>
            ))}
          </Box>

          <LanguageSelector />

          <>
            {!showAccount ? (
              <>
                <Box sx={{ display: 'flex' }}>
                  <Box>
                    <Button color="inherit" component={Link} to={AppRoutes.AUTH}>
                      {t('logIn')}
                    </Button>
                  </Box>

                  <Box sx={{ marginLeft: '20px' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      component={Link}
                      to={AppRoutes.REGISTER}
                    >
                      {t('signUp')}
                    </Button>
                  </Box>
                </Box>
              </>
            ) : (
              <AvatarMenu />
            )}
          </>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
